import { technicalApi } from "~/api/dashboard/analysis/technicalApi";
import { transformedTechnicalAnalysisPoints } from "~/utils/getTransformedData";

import { fundamentalApi } from "~/api/dashboard/analysis/fundamentalApi";
import { transformedFundamentalAnalysisData } from "~/utils/getTransformedData";

import { onChainApi } from "~/api/dashboard/analysis/onChainApi";
import { transformedAnalysisImages } from "~/utils/getTransformedData";

export const createOrEditAnalysis = (
  id,
  analysisType,
  setIsLoading,
  analysisValues,
  onSuccess,
  setErrorMessage,
  enableNotification,
  userId
) => {
  setIsLoading(true);

  if (analysisType === "technical") {
    const parameters = {
      pair_key: analysisValues.new.pair.key,
      analysis_type: "technical",
      position_type: analysisValues.technical.positionType,
      time_frame_id: analysisValues.technical.timeFrame.key,
      technical_type: analysisValues.technical.type.toLowerCase(),
      technical: transformedTechnicalAnalysisPoints(
        [
          ...analysisValues.technical.points.ep?.filter(
            (item) => item.value !== ""
          ),
        ],
        [
          ...analysisValues.technical.points.sl?.filter(
            (item) => item.value !== ""
          ),
        ],
        [
          ...analysisValues.technical.points.tp?.filter(
            (item) => item.value !== ""
          ),
        ]
      ).technical,
      description: analysisValues.technical.description,
      chart_data: JSON.stringify(analysisValues.technical.chart),
      notification_users: enableNotification
        ? [
            userId,
            ...analysisValues?.technical?.notificationUsers?.map(
              (user) => user.key
            ),
          ]
        : [],
    };
    if (id)
      technicalApi
        .edit(id, parameters)
        .then((res) => {
          if (res && !res.error && res.data && res.data?.data?.analysis?.uuid) {
            onSuccess(res.data?.data?.analysis?.uuid);
          } else if (res?.error) {
            if (res?.error.response?.data.errors) {
              setErrorMessage(res?.error.response?.data.errors);
            } else setErrorMessage([res?.error.response?.data.message]);
          }
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    else
      technicalApi
        .createNew(parameters)
        .then((res) => {
          if (res && !res.error && res.data) {
            onSuccess();
          } else if (res?.error) {
            if (res?.error.response?.data.errors) {
              setErrorMessage(res?.error.response?.data.errors);
            } else setErrorMessage([res?.error.response?.data.message]);
          }
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
  }

  if (analysisType === "fundamental") {
    const formData = new FormData();

    transformedFundamentalAnalysisData(
      formData,
      analysisValues.fundamental,
      analysisValues.new.pair.key,
      analysisValues.fundamental.chart
    );

    fundamentalApi
      .createNew(formData)
      .then((res) => {
        if (res && !res.error && res.data) {
          onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  }
  if (analysisType === "on-chain") {
    const formData = new FormData();
    transformedAnalysisImages(formData, analysisValues.onChain.images, [
      "on_chain",
      "picture",
    ]);
    formData.append("title", analysisValues.onChain.title);
    formData.append("description", analysisValues.onChain.description);
    formData.append("analysis_type", "on_chain");
    formData.append("pair_key", analysisValues.new.pair.key);
    onChainApi
      .createNew(formData)
      .then((res) => {
        if (res && !res.error && res.data) {
          onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  }
};
