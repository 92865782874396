import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { setupApi } from "~/api/dashboard/setupApi";

import { Loading } from "~/components/child/ui/Loading";
import Dropdown from "~/components/child/ui/DropDown";
import Description from "~/components/child/ui/Description";
import Button from "~/components/child/ui/Button";
import Accordion from "~/components/child/ui/Accordion";
import Modal from "~/components/child/ui/Modal";
import { PairsSymbol } from "~/components/child/Symbols";
import { HeatmapDetails } from "~/components/child/heatmapDetails";
import {
  ReceiveNotification,
  NotificationModal,
} from "~/components/child/notification/ReceiveNotification";
import { PointAmount, DeletePointConfirm } from "./PointAmount";

import { useHeatMapDetails, useVaultsList } from "~/utils/hooks";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";

import {
  sortDescending,
  sortAscending,
  calculateRiskParams,
  calculateSumWeight,
  sumWeightValidation,
  newSetupFormValidation,
} from "../helper";

const RiskParams = ({ label, supportingText, value }) => (
  <div className="text-oil-60 flex items-center w-full gap-4 font-roboto">
    <p className="text-storm-80 flex items-center gap-1 min-w-fit font-roboto text-sm">
      {label}
      {supportingText && (
        <span className="text-xs flex text-oil-60 ">({supportingText})</span>
      )}
    </p>
    <hr className="w-full border-black opacity-10 " />
    <span className="text-xs">{value}</span>
  </div>
);

const NewSetup = ({ user, permissions }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("heatmapId");
  const navigate = useNavigate();

  const {
    details,
    chartDetails,
    isLoading: heatMapDetailsIsLoading,
  } = useHeatMapDetails(id);
  const vaultsList = useVaultsList()?.data;

  const [switchIsOn, setSwitchIsOn] = useState(false);
  const [showModal, setShowModal] = useState({
    notification: false,
    deletedPoint: undefined,
  });
  const [sumWeight, setSumWeight] = useState({
    ep: 0,
    sl: 0,
    tp: 0,
  });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [setupValues, setSetupValues] = useState({
    vault: undefined,
    points: {
      ep: [],
      tp: [],
      sl: [],
    },
    usersId: [],
    description: "",
    maxDrawDown: 0,
    maxProfit: 0,
    RR: 0,
  });
  useEffect(() => {
    const { mdd, maxProfit, rr } = calculateRiskParams(
      setupValues.points.ep,
      setupValues.points.sl,
      setupValues.points.tp,
      details?.heatmap?.technical.type[0].toLowerCase()
    );
    setSetupValues({
      ...setupValues,
      maxDrawDown: mdd || 0,
      maxProfit: maxProfit || 0,
      RR: rr || 0,
    });
    setSumWeight({
      ep: calculateSumWeight(setupValues.points.ep),
      tp: calculateSumWeight(setupValues.points.tp),
      sl: calculateSumWeight(setupValues.points.sl),
    });
    setErrorMessage({ ...errorMessage, create: undefined });
  }, [setupValues.points]);

  useEffect(() => {
    if (sumWeight.ep !== 0 || sumWeight.tp !== 0 || sumWeight.sl !== 0)
      setErrorMessage({
        ...errorMessage,
        pointsWeight: {
          ep: sumWeightValidation(sumWeight.ep),
          tp: sumWeightValidation(sumWeight.tp),
          sl: sumWeightValidation(sumWeight.sl),
        },
      });
  }, [sumWeight]);

  const createNewSetup = () => {
    setIsLoading(true);
    setErrorMessage(undefined);
    setupApi
      .createNew({
        heatmap_uuid: id,
        pair_id: details?.heatmap?.pair?.key,
        position_type: details?.heatmap?.technical.type[0].toLowerCase(),
        vault_uuid: setupValues?.vault?.key,
        notification_users: switchIsOn
          ? [user.uuid, ...setupValues.usersId.map((user) => user.key)]
          : [],
        mdd: setupValues.maxDrawDown,
        max_profit: setupValues.maxProfit,
        rr: setupValues.RR,
        description: setupValues.description,
        position_size: 1,
        points: Object.entries(setupValues.points).flatMap(
          ([pointType, items]) => {
            return items.map((item) => ({
              point_type: pointType,
              amount: item.value,
              weight: item.weight,
              user_uuid: item.user,
            }));
          }
        ),
      })
      .then((res) => {
        if (res && !res.error && res.data) {
          navigate("/setupList");
        } else if (res?.error) {
          if (res?.error.response?.data?.errors) {
            setErrorMessage({
              ...errorMessage,
              create: res?.error?.response?.data.errors,
            });
          } else
            setErrorMessage({
              ...errorMessage,
              create: { error: [res?.error.response?.data.message] },
            });
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  const HeatmapDetailsProps = useMemo(
    () => ({
      id: id,
      selectedAnalyzers: details?.users ? [...details?.users] : [],
      details: details,
      chartDetails: chartDetails,
    }),
    [id, details, chartDetails]
  );

  return (
    <>
      {permissions?.full_access ? (
        <>
          {!heatMapDetailsIsLoading && details?.heatmap ? (
            <div className="main__router__container">
              <div className="main__router__container__view-box-container h-auto max-h-[85%]  w-full m-16  gap-2.5 flex flex-col scrollbar-style scrollbar-style-thumb">
                <div className="flex p-8 flex-col bg-base rounded-3xl">
                  <div className="flex flex-col gap-6 bg-storm-10 rounded-lg py-4 px-2.5">
                    <h3 className="text-lg font-robotoMed text-sky-70">
                      New Setup
                    </h3>
                    <div className="flex items-center justify-between">
                      <PairsSymbol
                        className="text-sm font-robotoMed"
                        image={details?.heatmap?.pair?.image}
                        pair={details?.heatmap?.pair?.pair}
                      />
                      <span
                        className={`py-1.5 px-2.5 rounded-2xl text-sm font-normal font-roboto ${
                          details?.heatmap?.technical.type[0].startsWith("L")
                            ? "bg-twilight-30 text-twilight-70"
                            : "text-infrared-40 bg-infrared-10"
                        }  `}
                      >
                        {changeWordFormatToTitleCase(
                          details?.heatmap?.technical.type[0]
                        )}
                      </span>
                    </div>

                    <div className="flex items-start w-full justify-between gap-2">
                      <Dropdown
                        bgColor="base"
                        value={{
                          value: setupValues?.vault?.value,
                        }}
                        inputStartAdornment={
                          <span className="flex text-oil-60 font-robotoMed pl-2 py-6">
                            Select Vault
                          </span>
                        }
                        optionsList={vaultsList?.map((item) => ({
                          key: item.uuid,
                          value: item.name,
                        }))}
                        renderItem={(item) => (
                          <span className="py-2 text-xs font-roboto text-left">
                            {item?.value}
                          </span>
                        )}
                        onSelect={(item) =>
                          setSetupValues({ ...setupValues, vault: item })
                        }
                      />
                      <ReceiveNotification
                        users={[...setupValues.usersId]}
                        onClickHandler={() =>
                          setShowModal({
                            ...showModal,
                            notification: true,
                          })
                        }
                        switchIsOn={switchIsOn}
                        setSwitchIsOn={setSwitchIsOn}
                      />
                    </div>
                    <div className="flex items-center gap-8">
                      <RiskParams
                        label="MDD"
                        supportingText="Max DrawDown"
                        value={`${setupValues.maxDrawDown}%`}
                      />

                      <RiskParams
                        label="Max Profit"
                        value={`${setupValues.maxProfit}%`}
                      />
                      <RiskParams label="R.R" value={setupValues.RR} />
                    </div>
                    <div className="flex items-start gap-4">
                      <PointAmount
                        label="ep"
                        labelColor="sky-key"
                        points={details?.heatmap?.technical.ep}
                        setupValues={setupValues}
                        setSetupValues={setSetupValues}
                        sortFunction={
                          details?.heatmap?.technical.type[0] === "LONG"
                            ? sortAscending
                            : sortDescending
                        }
                        userId={user.uuid}
                        onDeletePoint={(value) =>
                          setShowModal({ ...showModal, deletedPoint: value })
                        }
                      />
                      <PointAmount
                        label="tp"
                        labelColor="leaf-key"
                        points={details?.heatmap?.technical.tp}
                        setupValues={setupValues}
                        setSetupValues={setSetupValues}
                        sortFunction={
                          details?.heatmap?.technical.type[0] === "LONG"
                            ? sortAscending
                            : sortDescending
                        }
                        userId={user.uuid}
                        onDeletePoint={(value) =>
                          setShowModal({ ...showModal, deletedPoint: value })
                        }
                      />
                      <PointAmount
                        label="sl"
                        labelColor="infrared-key"
                        points={details?.heatmap?.technical.sl}
                        setupValues={setupValues}
                        setSetupValues={setSetupValues}
                        userId={user.uuid}
                        sortFunction={
                          details?.heatmap?.technical.type[0] === "LONG"
                            ? sortDescending
                            : sortAscending
                        }
                        onDeletePoint={(value) =>
                          setShowModal({ ...showModal, deletedPoint: value })
                        }
                      />
                    </div>
                  </div>
                  <Description
                    description={setupValues.description}
                    setDescription={(value) =>
                      setSetupValues({ ...setupValues, description: value })
                    }
                    limitNumber={7000}
                  />
                  <div className="flex items-center w-full mt-6 gap-4">
                    <Button
                      onClick={() => navigate(`/heatmap/${id}`)}
                      className="w-1/2"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={newSetupFormValidation(
                        sumWeight,
                        errorMessage,
                        isLoading,
                        setupValues
                      )}
                      className="w-1/2"
                      variant="primary"
                      isLoading={isLoading}
                      onClick={() => {
                        createNewSetup();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                  {errorMessage && errorMessage.create ? (
                    Object.values(errorMessage.create).length > 0 ? (
                      Object.values(errorMessage.create).flatMap((error) => (
                        <p className="mt-2 font-roboto text-sm text-infrared-key">
                          {error}
                        </p>
                      ))
                    ) : (
                      <p className="mt-2 font-roboto text-sm text-infrared-key">
                        {errorMessage.create}
                      </p>
                    )
                  ) : (
                    errorMessage?.pointsWeight &&
                    Object.keys(errorMessage?.pointsWeight)
                      .filter((key) => errorMessage?.pointsWeight[key])
                      ?.map((key) => (
                        <p className="mt-2 font-roboto text-sm text-infrared-key">
                          {`The total weight of ${key.toUpperCase()}s must be 100%`}
                        </p>
                      ))
                  )}
                </div>
                <div className="flex p-8 flex-col bg-base rounded-3xl">
                  <Accordion
                    className={{ root: "p-0" }}
                    label={{
                      leftPart: (
                        <h3 className="font-robotoMed text-sky-70 text-lg">
                          Heat map Details
                        </h3>
                      ),
                    }}
                    openDefault={true}
                  >
                    <div className="">
                      <hr className="border-black opacity-10 w-full" />
                      <HeatmapDetails
                        {...HeatmapDetailsProps}
                        mode="setup"
                        className="py-4"
                      />
                    </div>
                  </Accordion>
                </div>
              </div>
              {showModal.deletedPoint && (
                <Modal>
                  <DeletePointConfirm
                    pointInfo={{ ...showModal.deletedPoint }}
                    confirmHandler={() => {
                      setSetupValues({
                        ...setupValues,
                        points: {
                          ...setupValues.points,
                          [showModal.deletedPoint.label]: setupValues.points[
                            showModal.deletedPoint.label
                          ].filter(
                            (item) => item.id !== showModal.deletedPoint.id
                          ),
                        },
                      });
                      setShowModal({
                        ...showModal,
                        deletedPoint: undefined,
                      });
                    }}
                    cancelHandler={() => {
                      setShowModal({
                        ...showModal,
                        deletedPoint: undefined,
                      });
                    }}
                  />
                </Modal>
              )}
              {showModal.notification && (
                <Modal className="w-2/5 min-h-[31.25rem]">
                  <NotificationModal
                    selectedUsers={setupValues.usersId}
                    setShowNotificationModal={(value) =>
                      setShowModal({ ...showModal, notification: value })
                    }
                    onSelectUser={(item) =>
                      setSetupValues({
                        ...setupValues,
                        usersId: onChangeCheckboxes(setupValues.usersId, {
                          key: item.key,
                          value: item.value,
                        }),
                      })
                    }
                  />
                </Modal>
              )}
            </div>
          ) : (
            <Loading size="large" />
          )}
        </>
      ) : (
        permissions !== undefined && (
          <p className="my-[50%] mx-auto text-infrared-key">
            You don't have permission to add new analysis.
          </p>
        )
      )}
    </>
  );
};

export default NewSetup;
