import React, { useState, useEffect } from "react";

import { usersApi } from "~/api/dashboard/usersApi";

import EyeHiddenSvg from "~/assets/icon/svgJsx/EyeHiddenSvg";
import EyeShowSvg from "~/assets/icon/svgJsx/EyeShowSvg";
import profileImg from "~/assets/icon/profile.svg";
import mobileIcon from "~/assets/icon/mobile.svg";
import telegramIcon from "~/assets/icon/telegram.svg";

import Modal from "~/components/child/ui/Modal";

import { useKeySubmission } from "~/utils/hooks";

const Profile = ({ user, updateUser }) => {
  const [showHiddenPassword, setShowHiddenPassword] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    phoneNumber: { value: "", isChanged: false },
    telegramId: { value: "", isChanged: false },
    password: { value: null, isChanged: false },
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setUserInfo({
      phoneNumber: { ...userInfo.phoneNumber, value: user?.phone_number },
      telegramId: { ...userInfo.telegramId, value: user?.telegram_id },
      password: { ...userInfo.password, value: userInfo.password.value },
    });
  }, [user]);

  const onSave = (e) => {
    e?.preventDefault();
    setIsLoading(true);
    const body = {
      telegram_id: userInfo.telegramId.value,
      password:
        userInfo.password.value?.length > 0 ? userInfo.password.value : null,
      phone_number: userInfo.phoneNumber.value,
    };
    usersApi.editProfile(user.uuid, body).then((res) => {
      if (res && !res.error && res?.data) {
        setIsLoading(false);
        setShowConfirmModal(false);
        updateUser({
          ...user,
          telegram_id: body.telegram_id,
          password: body.password,
          phone_number: body.phone_number,
        });
        setUserInfo(
          Object.keys(userInfo).reduce((acc, key) => {
            acc[key] = { ...userInfo[key], isChanged: false };
            return acc;
          }, {})
        );
      } else if (res?.error) {
        setIsLoading(false);

        setErrorMessage(res?.error.response?.data.message);
      }
    });
  };

  useKeySubmission(
    onSave,
    () => isLoading || errorMessage.length > 0,
    (event) => event.key === "Enter" && showConfirmModal,
    []
  );

  return (
    <div className="main__router__container">
      <div className="profile">
        <span className="profile__avatar">
          <img src={profileImg} alt="profile" />
        </span>
        <span className="profile__email">{user?.email}</span>

        <form className="profile__form">
          <div className="select-cstm-one ">
            <div className="select-cstm-one__title ">Phone number</div>
            <input
              maxLength={11}
              value={userInfo?.phoneNumber.value}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  phoneNumber: {
                    value: e?.target?.value,
                    isChanged:
                      e?.target?.value.length === 11 &&
                      e?.target?.value !== user?.phone_number
                        ? true
                        : false,
                  },
                })
              }
              type="text"
              name="phoneNumber"
            />
            <img src={mobileIcon} alt="mobile icon" />
          </div>
          <div className="select-cstm-one ">
            <div className="select-cstm-one__title ">Telegram ID</div>
            <input
              value={userInfo?.telegramId.value}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  telegramId: {
                    value: e.target.value,
                    isChanged:
                      e?.target?.value !== "" &&
                      e?.target?.value !== user?.telegram_id
                        ? true
                        : false,
                  },
                })
              }
              type="text"
              name="telegramId"
            />
            <img src={telegramIcon} alt="telegram icon" />
          </div>

          <div className="select-cstm-one ">
            <div className="select-cstm-one__title ">Password</div>

            <input
              value={
                !userInfo?.password?.value?.includes(" ")
                  ? userInfo?.password?.value
                  : null
              }
              className="password"
              onChange={(e) => {
                if (!e?.target?.value?.includes(" "))
                  setUserInfo({
                    ...userInfo,
                    password: {
                      value: e.target.value,
                      isChanged:
                        e?.target?.value !== null && e?.target?.value !== ""
                          ? true
                          : false,
                    },
                  });
              }}
              // style={{ fontSize: showHiddenPassword ? "40px" : "unset" }}
              type={showHiddenPassword ? "password" : "text"}
              name="password"
            />

            <div
              onClick={() => setShowHiddenPassword(!showHiddenPassword)}
              className="select-cstm-one__passwordIcon"
            >
              {showHiddenPassword ? <EyeShowSvg /> : <EyeHiddenSvg />}
            </div>
          </div>
          <button
            disabled={
              isLoading ||
              !Object.values(userInfo).some((item) => item?.isChanged)
            }
            className="btn-cstm btn-dark-cstm profile__form__submit"
            onClick={() => setShowConfirmModal(true)}
            type="button"
          >
            Save changes
          </button>
          {showConfirmModal && (
            <Modal>
              <p className="text-soil-dark font-roboto text-base font-normal leading-snug m-0">
                you are changing your{" "}
                {Object.entries(userInfo)
                  .filter(([key, value]) => value.isChanged)
                  .map(([key, value]) => {
                    if (key === "phoneNumber") return "phone number";
                    else if (key === "telegramId") return "telegram Id";
                    return "password";
                  })
                  .map((item, index) => {
                    if (index > 0) return ` and ${item}`;
                    return item;
                  })}
                , if you are okay, press save changes button.
              </p>
              <div className="flex items-center flex-row-reverse gap-4 justify-between">
                <button
                  disabled={isLoading || errorMessage.length > 0}
                  className="btn-cstm btn-dark-cstm"
                  onClick={(e) => onSave(e)}
                  type="submit"
                >
                  Save changes
                </button>

                <button
                  onClick={() => {
                    setErrorMessage("");
                    setShowConfirmModal(false);
                  }}
                  className="btn-cstm btn-outline-dark-cstm"
                >
                  cancel
                </button>
              </div>
              {errorMessage && (
                <p className="profile__confirm__alert__error">{errorMessage}</p>
              )}
            </Modal>
          )}
        </form>
      </div>
    </div>
  );
};

export default Profile;
