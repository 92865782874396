import React, { useRef, useState, useEffect } from "react";

import AnalysisHeader from "~/components/child/AnalysisHeader";
import DatePicker from "~/components/child/ui/datePicker/DatePicker";
import FundamentalEntry from "~/components/child/FundamentalEntry";
import Description from "~/components/child/ui/Description";
import ImagesPreview from "~/components/child/ui/imageUpload/Preview";
import UploadImage from "~/components/child/ui/imageUpload/Upload";

import copy from "~/assets/icon/copy.svg";

const Details = ({ analysisValues, setAnalysisValues }) => {
  const [entry, setEntry] = useState(undefined);
  const [editItemId, setEditItemId] = useState(undefined);

  const textarea = useRef();

  const emitDatePicker = (value) => {
    setEntry({
      ...entry,
      date:
        value !== null
          ? `${
              value?.month.number < 10
                ? `0${value?.month.number}`
                : value?.month.number
            }/${value?.day}/${value?.year}`
          : undefined,
    });
  };

  useEffect(() => {
    const editItem = analysisValues.fundamental.entries.find(
      (item) => item.id === editItemId
    );
    if (editItem)
      setEntry({
        date: editItem.date,
        title: editItem.title,
        tooltip: editItem.tooltip,
      });
  }, [editItemId]);

  return (
    <div className="fundamental-details__box">
      <div className="fundamental-details__box__data-add">
        <div className="fundamental-details__box__data-add__title">
          New analysis
        </div>

        <AnalysisHeader pairs={analysisValues.new.pair} type="Fundamental" />
        <div className="fundamental-details__box__data-add__input-write__datePicker">
          <DatePicker
            date={entry?.date || ""}
            emitDatePicker={emitDatePicker}
            forcible={true}
          />
        </div>

        <div className="input-box-cstm-one fundamental-details__box__data-add__input-write">
          <div className="fundamental-details__box__data-add__input-write__title">
            <span>Title&nbsp;*</span>
          </div>
          <div className="fundamental-details__box__data-add__input-write__title__value">
            <input
              value={entry?.title || ""}
              onChange={(event) =>
                setEntry({ ...entry, title: event.target.value })
              }
              type="text"
            />
          </div>
        </div>

        <div className="input-box-cstm-one fundamental-details__box__data-add__input-write">
          <div className="fundamental-details__box__data-add__input-write__copy-icon">
            <i>
              <img src={copy} alt="" />
            </i>
          </div>

          <div className="fundamental-details__box__data-add__input-write__textarea">
            <textarea
              ref={textarea}
              className="scrollbar-style scrollbar-style-thumb"
              placeholder="* write something"
              name=""
              id=""
              value={entry?.tooltip || ""}
              onChange={(event) =>
                setEntry({ ...entry, tooltip: event.target.value })
              }
            ></textarea>
          </div>
        </div>

        <div className="fundamental-details__box__data-add__add">
          <button
            disabled={
              entry?.title === undefined ||
              entry.title === "" ||
              entry?.tooltip === undefined ||
              entry?.tooltip === "" ||
              entry?.date === undefined
            }
            onClick={() => {
              if (editItemId !== undefined) {
                setAnalysisValues({
                  ...analysisValues,
                  fundamental: {
                    ...analysisValues.fundamental,
                    entries: analysisValues.fundamental.entries?.map((item) => {
                      if (item.id === editItemId)
                        return {
                          ...item,
                          date: entry.date,
                          title: entry.title,
                          tooltip: entry.tooltip,
                        };

                      return item;
                    }),
                  },
                });

                setEditItemId(undefined);
              } else {
                setAnalysisValues({
                  ...analysisValues,
                  fundamental: {
                    ...analysisValues.fundamental,
                    entries: [
                      ...analysisValues.fundamental.entries,
                      {
                        id:
                          analysisValues.fundamental.entries.length > 0
                            ? analysisValues.fundamental.entries[
                                analysisValues.fundamental.entries.length - 1
                              ].id + 1
                            : 0,
                        date: entry.date,
                        title: entry.title,
                        tooltip: entry.tooltip,
                      },
                    ],
                  },
                });
              }
              setEntry(undefined);
            }}
          >
            {editItemId === undefined ? "Add" : "Edit"}
          </button>
        </div>
      </div>
      <div className="fundamental-details__box__entries">
        {analysisValues.fundamental.entries.length > 0 && (
          <div className="fundamental-details__box__entries__title">
            <span>your entries</span>
          </div>
        )}

        {analysisValues.fundamental.entries.length > 0 &&
          analysisValues.fundamental.entries?.map((item) => (
            <FundamentalEntry
              id={item?.id}
              onDelete={() => {
                setAnalysisValues({
                  ...analysisValues,
                  fundamental: {
                    ...analysisValues.fundamental,
                    entries: analysisValues.fundamental.entries.filter(
                      (entry) => entry.id !== item?.id
                    ),
                  },
                });
                if (editItemId === item?.id) {
                  setEditItemId(undefined);
                  setEntry(undefined);
                }
              }}
              onEdit={() => {
                setEditItemId(item?.id);
              }}
              label={item?.title}
              date={item.date}
              description={item?.tooltip}
            />
          ))}
      </div>
      <Description
        description={analysisValues.fundamental.description}
        setDescription={(value) =>
          setAnalysisValues({
            ...analysisValues,
            fundamental: {
              ...analysisValues.fundamental,
              description: value,
            },
          })
        }
        limitNumber={8000}
      />
      <div className="fundamental-details__box__images">
        <UploadImage
          information="Add photo (maximum 5 photos)"
          onAddNewImage={(e) => {
            if (e.target.files[0])
              setAnalysisValues({
                ...analysisValues,
                fundamental: {
                  ...analysisValues.fundamental,
                  images: [
                    ...analysisValues.fundamental.images,
                    {
                      id:
                        analysisValues.fundamental.images.length > 0
                          ? analysisValues.fundamental.images[
                              analysisValues.fundamental.images.length - 1
                            ].id + 1
                          : 0,
                      name: e.target.files[0].name,
                      src: URL.createObjectURL(e.target.files[0]),
                      file: e.target.files[0],
                      active:
                        analysisValues.fundamental.images?.length === 0 ? 1 : 0,
                    },
                  ],
                },
              });
          }}
        />

        <ImagesPreview
          onRemove={(id) =>
            setAnalysisValues({
              ...analysisValues,
              fundamental: {
                ...analysisValues.fundamental,
                images: analysisValues.fundamental.images.filter(
                  (image) => image.id !== id
                ),
              },
            })
          }
          onSetAsPrimary={(id) =>
            setAnalysisValues({
              ...analysisValues,
              fundamental: {
                ...analysisValues.fundamental,
                images: analysisValues.fundamental.images.map((image) => ({
                  ...image,
                  active: id === image.id ? 1 : 0,
                })),
              },
            })
          }
          images={analysisValues.fundamental.images}
        />
      </div>
    </div>
  );
};

export default Details;
