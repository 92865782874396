import React, { useState, useEffect } from "react";

import { vmcApi } from "~/api/dashboard/valumeMarketCap";

import Button from "~/components/child/ui/Button";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./secondStep";
import { Charts } from "./secondStep/Chart";

import vmcSvg from "~/assets/icon/vmc.svg";
import { Loading } from "../../../child/ui/Loading";

const ValumeMarketCap = () => {
  const [step, setStep] = useState(1);
  const [vmcData, setVmcData] = useState({
    inputData: {
      pairs: [],
      intervals: [],
      movingAverageCandles: undefined,
      standardDuration: undefined,
    },
    carts: undefined,
    charts: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const submitInputData = async () => {
    setIsLoading(true);
    const inputData = {
      pairs: vmcData.inputData?.pairs?.map((item) => item?.key),
      intervals: vmcData.inputData.intervals.map((item) =>
        item.value.toLowerCase()
      ),
      moving_average_period: Number(vmcData?.inputData?.movingAverageCandles),
      standard_deviation_multiplier: Number(
        vmcData?.inputData?.standardDuration
      ),
    };
    try {
      const result = await vmcApi.inputData(inputData);
      if (result && result.data && !result.error)
        setVmcData({
          ...vmcData,
          carts: result?.data?.flat(),
        });
      else setError(result.error.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching pairs data:", error);
    }
  };

  const fetchChartData = async (pair, interval) => {
    setIsLoading(true);
    const inputData = {
      pairs: [pair.key],
      intervals: [interval],
      moving_average_period: Number(vmcData?.inputData?.movingAverageCandles),
      standard_deviation_multiplier: Number(
        vmcData?.inputData?.standardDuration
      ),
    };
    try {
      const result = await vmcApi.chart(inputData);
      if (result && result.data && !result.error)
        setVmcData((prevState) => ({
          ...prevState,
          charts: vmcData.charts
            ? [...vmcData.charts, ...result?.data]
            : result?.data,
        }));
      else setError(result.error.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (vmcData?.carts && vmcData?.carts?.length > 0) setStep(2);
    else if (vmcData?.carts)
      setError("There wasn't any information related to your input data");
  }, [vmcData?.carts]);

  useEffect(() => {
    setError("");
  }, [vmcData.inputData]);

  return (
    <div className="main__router__container h-[94vh] overflow-x-hidden overflow-y-auto scrollbar-style-thumb scrollbar-style ">
      <div className="bg-base w-[35%] min-h-fit">
        <aside className={`flex flex-col m-4 py-4 px-2.5 gap-4 `}>
          <h4 className="text-base text-sky-70">Volume / Market cap</h4>
          {step === 1 ? (
            <>
              <FirstStep vmcData={vmcData} setVmcData={setVmcData} />
              <Button
                onClick={submitInputData}
                isLoading={isLoading}
                className="mt-8"
                variant="primary"
                disabled={
                  error.length > 0 ||
                  isLoading ||
                  vmcData?.inputData?.pairs?.length === 0 ||
                  vmcData.inputData.intervals?.length === 0 ||
                  !vmcData.inputData.movingAverageCandles ||
                  !vmcData.inputData.standardDuration
                }
              >
                Next
              </Button>
              <p className="text-sm font-roboto text-sky-70">{error}</p>
            </>
          ) : (
            step === 2 && (
              <>
                <SecondStep
                  onSelectCard={(pair, interval) =>
                    fetchChartData(pair, interval)
                  }
                  vmcData={vmcData}
                  setVmcData={setVmcData}
                  isLoading={isLoading}
                />
                <Button
                  onClick={() => {
                    setStep(1);
                    setVmcData((prevState) => ({
                      ...prevState,
                      charts: undefined,
                    }));
                  }}
                  className="mt-8"
                  variant="outline"
                >
                  Back
                </Button>
              </>
            )
          )}
        </aside>
      </div>
      <div
        className={`flex flex-row ${
          step === 1 ? "items-center" : "items-baseline"
        } justify-center w-full `}
      >
        {step === 1 ? (
          <img className=" w-2/5" src={vmcSvg} alt="" />
        ) : (
          step === 2 && (
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <Charts charts={vmcData?.charts} carts={vmcData.carts} />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default ValumeMarketCap;
